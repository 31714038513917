import { useEffect, useMemo, useState } from 'react';
import { fetchAllUsersAndLinks } from '../../../services/userService';

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  clicks: number;
  role: string;
  brand: string;
  createdAt: string;
  links: Link[];
  totalLinks?: number;
  totalClicks?: number;
  isWhitelisted?: boolean;
  linkCount?: number;
}

export interface Link {
  originalUrl: string;
  shortUrl: string;
  targetSite: string;
  clickCount: number;
}

const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const useUserColumns = (handleDeleteUser: (user: User) => void) => {
  return useMemo(
    () => [
      {
        key: 'totalClicks',
        title: 'Total Clicks',
        dataIndex: 'totalClicks',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
        render: (text: number, user: User) => {
          const totalClicks = user.links?.reduce(
            (sum, link) => sum + link.clickCount,
            0,
          );
          return totalClicks || 0;
        },
      },
      {
        key: 'links',
        title: 'Total Links',
        dataIndex: 'links.length',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
        render: (text: number, user: User) => {
          return user.links?.length || 0;
        },
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        width: '15%',
        headerAlign: 'left',
        cellAlign: 'left',
      },
      {
        key: 'firstName',
        title: 'First Name',
        dataIndex: 'firstName',
        width: '10%',
        headerAlign: 'left',
        cellAlign: 'left',
      },
      {
        key: 'lastName',
        title: 'Last Name',
        dataIndex: 'lastName',
        width: '10%',
        headerAlign: 'left',
        cellAlign: 'left',
      },
      {
        key: 'country',
        title: 'Country',
        dataIndex: 'country',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'city',
        title: 'City',
        dataIndex: 'city',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'role',
        title: 'Role',
        dataIndex: 'role',
        width: '5%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'whitelist',
        title: 'Whitelist',
        dataIndex: 'whitelist',
        width: '5%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'brand',
        title: 'Brand',
        dataIndex: 'brand',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
      },
      {
        key: 'createdAt',
        title: 'Created At',
        dataIndex: 'createdAt',
        width: '10%',
        headerAlign: 'center',
        cellAlign: 'center',
        render: (text: string) => formatDate(text),
      },
      {
        key: 'delete',
        title: 'Delete',
        dataIndex: 'delete',
        width: '5%',
        headerAlign: 'center',
        cellAlign: 'center',
        render: (text: string, user: User) => (
          <button
            className="text-red-500 hover:text-red-700"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteUser(user);
            }}
          >
            Delete
          </button>
        ),
      },
    ],
    [handleDeleteUser],
  );
};

export const useUsersData = (
  filter: string,
  page: number,
  limit: number,
  sortField: string,
  sortOrder: string,
) => {
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetchAllUsersAndLinks({
        filter,
        page,
        limit,
        sortField,
        sortOrder,
      });

      setUsers(response.data);
      setTotalUsers(response.pagination.totalUsers);
      setTotalPages(response.pagination.totalPages);
      setLoading(false);
    } catch (err) {
      setError('Failed to load users');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [filter, page, limit, sortField, sortOrder]);

  return { users, loading, error, totalPages, totalUsers, fetchUsers };
};
