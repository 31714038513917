import { useEffect } from 'react';
import { fetchMetaPixelScript } from '../../../services/fbpixel';

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
    _fbq?: (...args: any[]) => void;
  }
}

const MetaPixel = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      fetchMetaPixelScript().then((scriptText) => {
        if (!scriptText) {
          console.error('Meta Pixel: Script not loaded!');
          return;
        }

        const script = document.createElement('script');
        script.textContent = scriptText;
        document.head.appendChild(script);

        script.onload = () => {
          console.log('Meta Pixel: Loaded');
          if (window.fbq) {
            console.log('Meta Pixel: Tracking page view');
            window.fbq('init', '578927491446469');
            window.fbq('track', 'PageView');
          } else {
            console.error('Meta Pixel: Error loading script');
          }
        };
      });
    }
  }, []);

  return null;
};

export default MetaPixel;
