import { createContext, useState, useEffect, ReactNode } from 'react';
import {
  fetchRoleInBrand,
  fetchUser as apiFetchUser,
} from '../services/userService';
import { IUser } from '../interfaces/User';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { UserRole } from '../types/enums';
import { getUserSubscription } from '../services/linkService';
import { AuthService } from '../services/authService';
import { isPublicRoute } from '../routes/routeUtils';

interface UserContextType {
  user: IUser | null;
  setUser: (user: IUser | null) => void;
  loading: boolean;
  fetchUser: () => Promise<void>;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const loadUser = async () => {
    setLoading(true);
    try {
      const userData = await apiFetchUser();

      if (!userData && !isPublicRoute(location.pathname)) {
        navigate('/signin');
        return;
      }

      if (userData && userData.brand && userData.role === UserRole.BrandUser) {
        const brandMemberRole = await fetchRoleInBrand(
          userData._id,
          userData.brand,
        );
        userData.brandMemberRole = brandMemberRole;
      }

      const userSubscription = await getUserSubscription(userData._id);
      // Determine hasSubscription based on plan and status
      const hasSubscription =
        (userSubscription?.plan === 'monthly' ||
          userSubscription?.plan === 'yearly') &&
        userSubscription?.status !== 'canceled';

      userData.subscription = {
        plan: userSubscription?.plan || 'free', // Default to 'free' if no plan
        status: userSubscription?.status || 'inactive',
        hasSubscription,
      };

      setUser(userData);
    } catch (err) {
      if (!isPublicRoute(location.pathname)) {
        navigate('/signin');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Charger l'utilisateur uniquement si authentifié et si on n'est pas sur une route publique
    if (AuthService.isAuthenticated() && !isPublicRoute(location.pathname)) {
      loadUser();
    } else if (
      !AuthService.isAuthenticated() &&
      !isPublicRoute(location.pathname)
    ) {
      navigate('/signin');
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location.pathname, navigate]);

  if (loading && !isPublicRoute(location.pathname)) {
    return <Loading />;
  }

  return (
    <UserContext.Provider
      value={{ user, setUser, loading, fetchUser: loadUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
