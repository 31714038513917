import { Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Layout from '../components/layout/Layout';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import { UserProvider } from '../context/UserContext';
import { ROUTES } from './constants';
import Loading from '../components/common/Loading';
import AdminAnalyticsPage from '../components/Analytics/AdminAnalyticsPage';
import UsersTable from '@/components/dashboard/Admin/UsersTable';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const MyLinks = lazy(() => import('../pages/MyLinks'));
const BulkUpload = lazy(() => import('../pages/BulkUpload'));
const Analytics = lazy(() => import('../pages/Analytics'));
const UserManagement = lazy(() => import('../pages/UserManagement'));
const Pricing = lazy(() => import('../pages/Plans'));
const Profile = lazy(() => import('../pages/profile/Profile'));
const MyWallet = lazy(() => import('../pages/MyWallet'));
const Signin = lazy(() => import('../pages/Signin'));
const Signup = lazy(() => import('../pages/Signup'));
const SignupInfluencer = lazy(() => import('../pages/SignupInfluencer'));
const ForgetPassword = lazy(() => import('../pages/ForgetPassword'));
const Redirect = lazy(() => import('../pages/redirect'));
const SocialAuth = lazy(() => import('../pages/SocialAuth'));
const TokenRedirect = lazy(() => import('../pages/TokenRedirect'));
const SignupBrand = lazy(() => import('../pages/SignupBrand'));
const ActivateAccount = lazy(() => import('../pages/ActivateAccount'));
const Checkout = lazy(() => import('../pages/stripe/Checkout'));
const YoutubeAuthCallback = lazy(() => import('../pages/YoutubeAuthCallback'));

const RoutesConfig = () => {
  return (
    <UserProvider>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path={ROUTES.SIGNIN} element={<Signin />} />
            <Route path={ROUTES.SIGNUP} element={<Signup />} />
            <Route path={ROUTES.SOCIAL_AUTH} element={<SocialAuth />} />
            <Route
              path={ROUTES.SIGNUP_INFLUENCER}
              element={<SignupInfluencer />}
            />
            <Route path={ROUTES.SIGNUP_BRAND} element={<SignupBrand />} />
            <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
            <Route
              path={ROUTES.ACTIVATE_ACCOUNT}
              element={<ActivateAccount />}
            />
          </Route>

          <Route path={ROUTES.REDIRECT} element={<Redirect />} />
          <Route path={ROUTES.TOKEN_REDIRECT} element={<TokenRedirect />} />

          <Route element={<ProtectedRoutes />}>
            <Route path={ROUTES.HOME} element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path={ROUTES.MY_LINKS} element={<MyLinks />} />
              <Route path={ROUTES.BULK_UPLOAD} element={<BulkUpload />} />
              <Route path={ROUTES.ANALYTICS} element={<Analytics />} />
              <Route
                path={ROUTES.USER_MANAGEMENT}
                element={<UserManagement />}
              />
              <Route path={ROUTES.PROFILE} element={<Profile />} />
              <Route path={ROUTES.WALLET} element={<MyWallet />} />
              <Route path={ROUTES.PLANS} element={<Pricing />} />
              <Route path={ROUTES.CHECKOUT} element={<Checkout />} />
              <Route
                path={ROUTES.YOUTUBE_AUTH_CALLBACK}
                element={<YoutubeAuthCallback />}
              />
              <Route path="/admin/users" element={<UsersTable />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </UserProvider>
  );
};

export default RoutesConfig;
