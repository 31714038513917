const Checkbox = ({
  label,
  className,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & { label?: string }) => {
  return (
    <div className="flex items-center gap-3 mt-2 md:mt-0">
      <input
        type="checkbox"
        className={`w-5 h-5 accent-[#626262] ${className && className}`}
        {...props}
      />
      {label && <span className="font-bold font-header">{label}</span>}
    </div>
  );
};
export default Checkbox;
