import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline' | 'text' | 'transparent';
  className?: string;
  src?: string;
  tooltip?: string;
  effectDarkness?: number;
  hoverSrc?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  children,
  variant = 'transparent',
  className,
  src,
  tooltip,
  effectDarkness,
  hoverSrc,
  ...props
}) => {
  const baseClasses = 'p-0.5 rounded-full flex items-center justify-center';

  const variantClasses = {
    primary: 'bg-indigo-600 text-white hover:bg-indigo-700',
    secondary: 'bg-gray-100 text-gray-700 hover:bg-gray-200',
    outline:
      'bg-transparent border border-gray-300 text-gray-700 hover:bg-gray-50',
    text: 'bg-transparent text-indigo-600 hover:text-indigo-700 hover:bg-gray-50',
    transparent: 'bg-transparent hover:bg-gray-100',
  };

  return (
    <button
      className={classNames(baseClasses, variantClasses[variant], className)}
      {...props}
      title={tooltip}
    >
      {src ? (
        <img
          src={src}
          alt={tooltip || 'icon'}
          className="w-full h-full object-contain"
        />
      ) : (
        children
      )}
    </button>
  );
};

export default IconButton;
