import React, { useEffect, useState } from 'react';
import Pagination from './UserTablePagination';
import { User, useUserColumns, useUsersData } from './TableData';
import LinkTableModal from './LinksTableModal';
import { SearchIcon } from '../../../svg';
import { deleteUser, whitelistUser } from '../../../services/userService';
import { toast } from 'react-toastify';
import ConfirmModal from '../../common/ConfirmModal';
import Checkbox from '../../common/Checkbox';

const UsersTable: React.FC = () => {
  const [userFilter, setUserFilter] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [userPage, setUserPage] = useState(1);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const entriesPerPage = 15;

  const {
    users,
    totalUsers,
    loading: usersLoading,
    error: usersError,
    totalPages,
    fetchUsers,
  } = useUsersData(userFilter, userPage, entriesPerPage, sortField, sortOrder);
  const [userWhitelisted, setUserWhitelisted] = useState<
    Record<string, boolean>
  >({});
  useEffect(() => {
    const initialWhitelistState = users.reduce(
      (acc, user) => {
        acc[user._id] = user.isWhitelisted || false;
        return acc;
      },
      {} as Record<string, boolean>,
    );
    setUserWhitelisted(initialWhitelistState);
  }, [users]);
  const handleDeleteUser = async (userId: string) => {
    try {
      await deleteUser(userId);
      toast.success('User deleted successfully!');
      fetchUsers();
    } catch (error) {
      toast.error('Failed to delete user');
    }
  };

  const confirmDeleteUser = (user: User): void => {
    setUserToDelete(user);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    if (userToDelete) {
      await handleDeleteUser(userToDelete._id);
      setShowConfirmModal(false);
      setUserToDelete(null);
    }
  };

  const userColumns = useUserColumns(confirmDeleteUser);

  const handleSort = (field: string) => {
    if (field === 'whitelist') return;
    const newSortOrder =
      sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Users</h1>
      <div className="border-b-2 flex items-center w-full md:w-[300px] mb-4">
        <SearchIcon className={'size-5 text-gray-400'} />
        <input
          type="text"
          placeholder="Search"
          name="search"
          id="search"
          onChange={(e) => setUserFilter(e.target.value)}
          value={userFilter}
          className="border-none outline-none bg-transparent p-[10px]"
        />
      </div>

      {usersLoading ? (
        <p>Loading users...</p>
      ) : usersError ? (
        <p>{usersError}</p>
      ) : (
        <>
          <div className="overflow-x-auto rounded-2xl border border-gray-200 mt-1">
            <table className="min-w-full table-auto rounded-3xl">
              <thead>
                <tr className="bg-gray-100">
                  {userColumns.map((column) => (
                    <th
                      key={column.key}
                      className="font-medium px-4 py-2 cursor-pointer"
                      style={{
                        width: column.width,
                        textAlign:
                          column.headerAlign as React.CSSProperties['textAlign'],
                      }}
                      onClick={() => handleSort(column.key)}
                    >
                      {column.title}{' '}
                      {sortField === column.key &&
                        (sortOrder === 'asc' ? '↑' : '↓')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {users
                  .slice()
                  .sort((a, b) => {
                    const aValue = (a as any)[sortField];
                    const bValue = (b as any)[sortField];
                    if (sortOrder === 'asc') {
                      return aValue > bValue ? 1 : -1;
                    } else {
                      return aValue < bValue ? 1 : -1;
                    }
                  })
                  .map((user) => (
                    <tr
                      key={user._id}
                      className="bg-white hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleUserSelect(user)}
                    >
                      {userColumns.map((column) => (
                        <>
                          {column.key !== 'whitelist' ? (
                            <td
                              key={`${user._id}-${column.key}`}
                              className="whitespace-nowrap overflow-hidden px-4 py-2 text-sm font-medium text-gray-900"
                              style={{
                                textAlign:
                                  column.cellAlign as React.CSSProperties['textAlign'],
                                width: column.width,
                              }}
                            >
                              {column.render
                                ? column.render(
                                    (user as any)[column.dataIndex],
                                    user,
                                  )
                                : (user as any)[column.dataIndex]}
                            </td>
                          ) : (
                            <td
                              key={`${user._id}-${column.key}`}
                              className="whitespace-nowrap overflow-hidden px-4 py-2 text-sm font-medium text-gray-900"
                              style={{
                                textAlign:
                                  column.cellAlign as React.CSSProperties['textAlign'],
                                width: column.width,
                              }}
                            >
                              <Checkbox
                                checked={userWhitelisted[user._id]}
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  try {
                                    // Call the API and get the updated whitelist status
                                    const updatedUser = await whitelistUser(
                                      user._id,
                                    );

                                    // Update the local state with the API response
                                    if (updatedUser && updatedUser.user) {
                                      setUserWhitelisted((prev) => ({
                                        ...prev,
                                        [user._id]:
                                          updatedUser.user.isWhitelisted,
                                      }));
                                    }

                                    // Show the appropriate toast message
                                    toast.success(
                                      updatedUser.user.isWhitelisted
                                        ? `User whitelisted successfully!`
                                        : `User removed from whitelisted users successfully!`,
                                    );
                                  } catch (error) {
                                    toast.error(
                                      'Failed to update whitelist status',
                                    );
                                  }
                                }}
                              />
                            </td>
                          )}
                        </>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <Pagination
            totalItems={totalUsers}
            itemsPerPage={entriesPerPage}
            currentPage={userPage}
            onPageChange={setUserPage}
            limit={{
              start: (userPage - 1) * entriesPerPage + 1,
              end: Math.min(
                userPage * entriesPerPage,
                totalPages * entriesPerPage,
              ),
            }}
          />
        </>
      )}

      {/* Modal to show the selected user's links */}
      {showModal && selectedUser && (
        <LinkTableModal links={selectedUser.links} handleClose={closeModal} />
      )}

      {/* Confirmation Modal */}
      <ConfirmModal
        isOpen={showConfirmModal}
        onRequestClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmDelete}
        message={`Are you sure you want to delete this user ${userToDelete?.email} ?`}
      />
    </div>
  );
};

export default UsersTable;
