import axiosInstance from '../config/axiosConfig';
import { YouTubeAnalyticsResponse } from '../interfaces/Analytics';
import { ILink, LinksResponse } from '../interfaces/Link';
import { SubscriptionPlan, SubscriptionResponse } from '../types/interfaces';

export const getUserLinks = async (
  userId: string,
  filters = {},
): Promise<any | null> => {
  try {
    const response = await axiosInstance.get<LinksResponse>(
      `/users/${userId}/links`,
      {
        params: {
          ...filters,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error when fetching user links', error);
    return null;
  }
};

export const createLink = (userId: string, link: string) => {
  return axiosInstance.post(`/users/${userId}/links`, {
    originalUrl: link,
  });
};

export const updateLink = (userId: string, link: ILink) => {
  try {
    return axiosInstance.put(`/users/${userId}/links/${link._id}`, {
      shortUrl: link.shortUrl,
      tags: link.tags,
      subDomain: link.subDomain,
      domain: link.domain,
    });
  } catch (error) {
    console.error('Error when updating link', error);
    return null;
  }
};

export const deleteLinks = (userId: string, linksId: string[]) => {
  const data = {
    linkIds: linksId,
  };
  try {
    return axiosInstance.delete(`/users/${userId}/links`, {
      data,
    });
  } catch (error) {
    console.error('Error when creating link', error);
    return null;
  }
};

export const subscribePlan = async (userId: string, data: any) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/subscribe`,
      data,
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching top sources', error);
    return null;
  }
};

export const subscribeUseToPlan = async (
  userId: string,
  data: { plan: SubscriptionPlan },
) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/subscribe`,
      data,
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching top sources', error);
    return null;
  }
};

export const getUserSubscription = async (userId: string) => {
  try {
    const response = await axiosInstance.get<SubscriptionResponse>(
      `/users/${userId}/subscription`,
    );

    return response.data.subscription;
  } catch (error: any) {
    console.error('Error fetching user subscription:', error);
    return null;
  }
};

export const cancelSubscriptionAPI = async (
  userId: string,
  cancellationReason: string,
) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/subscription/cancel`,
      { cancellationReason },
    );

    return response.data;
  } catch (error) {
    console.error('Error canceling subscription', error);
    return null;
  }
};

export const resumeSubscriptionAPI = async (userId: string) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/subscription/resume`,
    );

    return response.data;
  } catch (error) {
    console.error('Error resuming subscription', error);
    return null;
  }
};

export type OrderType = {
  _id: string;
  userId: string;
  title: string;
  purchaseDate: string;
  stripeCustomer: string;
  amount: number;
  currency: string;
  invoiceId: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export const getOrders = async (userId: string) => {
  try {
    const response = await axiosInstance.get<{ orders: OrderType[] }>(
      `/users/${userId}/orders`,
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching top sources', error);
    return null;
  }
};

export const isLinkAvailable = async (
  userId: string,
  linkId: string,
  shortUrl: string,
) => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/links/${linkId}/is-link-available`,
      { params: { shortUrl } },
    );

    return response.data;
  } catch (error) {
    console.error('Error when fetching brand users', error);
    return null;
  }
};

export const addToFavorites = async (linkId: string, userId: string) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/links/${linkId}/add-favorites`,
    );
    return response.data;
  } catch (error) {
    console.error('Error adding to favorites:', error);
    throw error;
  }
};

export const removeFromFavorites = async (linkId: string, userId: string) => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/links/${linkId}/remove-favorites`,
    );
    return response.data;
  } catch (error) {
    console.error('Error removing from favorites:', error);
    throw error;
  }
};

export const uploadMetaImage = async (
  userId: string,
  linkId: string,
  file: FormData,
): Promise<ILink> => {
  try {
    const response = await axiosInstance.post(
      `/users/${userId}/links/${linkId}/meta-image`,
      file,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error uploading meta image:', error);
    throw error;
  }
};

export const updateMetaTitleDesc = async (
  userId: string,
  linkId: string,
  metaTitle: string,
  metaDescription: string,
): Promise<ILink> => {
  try {
    const response = await axiosInstance.put(
      `/users/${userId}/links/${linkId}/meta`,
      {
        metaTitle,
        metaDescription,
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error updating meta title and description:', error);
    throw error;
  }
};

export const getYouTubeStatisticsForLink = async (
  userId: string,
  linkId: string,
  interval: string,
): Promise<YouTubeAnalyticsResponse | null> => {
  try {
    const response = await axiosInstance.get(
      `/users/${userId}/links/${linkId}/youtube-statistics?interval=${interval}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error when fetching clicks for link', error);
    return null;
  }
};
