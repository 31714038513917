import { useContext, useEffect, useState, useCallback } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Dropdown from '../common/Dropdown';
import ProgressBar from '../common/ProgressBar';
import { sidebarData } from '../../config/sidebarData';
import { LogoutIcon, PremiumIcon, SinglePersonIcon } from '../../svg';
import { ProfileCompletion } from '../../services/userService';
import { UserContext } from '../../context/UserContext';
import useDeviceDetect from '../../helpers/screens';
import { AuthService } from '../../services/authService';
import { BrandMemberRole } from '../../types/enums';
import UpgradeRequiredModal from '../Modal/UpgradeRequiredModal';
import { Button } from '../common/Button';
import IconButton from '../common/IconButton';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../common/LanguageSelector';
import { TFunction } from 'i18next';
import Copyright from '../common/Copyright';

const Navbar = () => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const [expand, setExpand] = useState<boolean>(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const userContext = useContext(UserContext);
  const [completion, setCompletion] = useState(0);
  const { t } = useTranslation();

  if (!userContext) {
    throw new Error('useContext must be used within a UserProvider');
  }

  const { user } = userContext;
  const hasSubscription =
    user?.subscription?.hasSubscription || user?.isWhitelisted;

  useEffect(() => {
    const fetchProfileCompletion = async () => {
      if (!user) return;
      const data = await ProfileCompletion(user._id);
      setCompletion(data);
    };

    fetchProfileCompletion();
  }, [user]);

  useEffect(() => {
    if (isMobile && expand) {
      const handleScroll = () => {
        setExpand(false);
      };
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile, expand]);

  const toggleExpand = useCallback(() => {
    setExpand((prevExpand) => !prevExpand);
  }, []);

  const handleBoostClick = () => {
    navigate('/plans');
  };

  const handleCompleteProfileClick = () => {
    navigate('/profile');
  };

  const logout = () => {
    AuthService.logout();
  };

  const handleUpgradeClick = () => {
    setIsUpgradeModalOpen(false);
    navigate('/plans');
  };

  const displayForBrand = (
    val: { access?: string; label?: string } | string,
  ): boolean => {
    if (
      user !== undefined &&
      user !== null &&
      user.brandMemberRole !== BrandMemberRole.User &&
      user.brandMemberRole !== BrandMemberRole.Manager
    )
      return true;
    else if (
      user !== undefined &&
      user !== null &&
      user.brandMemberRole === BrandMemberRole.Manager &&
      typeof val === 'object' &&
      val.label?.toLowerCase() === 'user management'
    ) {
      return true;
    }
    return typeof val === 'object' ? val.access === 'free' : false;
  };

  const displayCompletion = () =>
    completion !== 100 && user && user.role !== 'admin';

  const renderProfileDropdown = (t: TFunction<'translation', undefined>) => (
    <Dropdown
      label={(isOpen) => (
        <IconButton
          tooltip={String(t('common.profile'))}
          src={
            isOpen
              ? '/assets/svgs/fi_avatar_hover.svg'
              : '/assets/svgs/fi_avatar.svg'
          }
          hoverSrc="/assets/svgs/fi_avatar_hover.svg"
        />
      )}
      side="right"
    >
      <ul className="w-[150px] flex flex-col items-center bg-white border shadow-lg rounded-lg py-2 sm:translate-x-0 translate-x-[100px]">
        <li className="hover:bg-gray-100 w-full">
          <Link to="/profile" className="flex items-center p-2">
            <SinglePersonIcon className="size-5 mr-2" onClick={() => null} />
            <span>{t('common.profile')}</span>
          </Link>
        </li>
        <li
          className="hover:bg-gray-100 w-full p-2 flex items-center"
          onClick={logout}
        >
          <LogoutIcon className="size-5 mr-2" onClick={undefined} />
          <span>{t('common.logout')}</span>
        </li>
      </ul>
    </Dropdown>
  );

  return (
    <nav className="w-full flex flex-row justify-between items-center z-50 border-b">
      {!isMobile && (
        <>
          <div className="w-1/5 px-[24px] py-[16px]">
            <Link to="/">
              <img
                src="/assets/Logo.svg"
                className="min-w-[150px] w-[190px] min-h-[40px] h-[28px] object-contain"
                alt="logo"
              />
            </Link>
          </div>
          <div className="w-4/5 flex justify-between px-[24px]">
            {
              <div className="flex flex-col lg:flex-row items-center w-[375px] lg:h-[44px] lg:my-[26px]">
                {!hasSubscription &&
                  displayForBrand('') &&
                  user?.role !== 'admin' && (
                    <Button onClick={handleBoostClick} color="secondary">
                      {t('common.upgradeNow')}
                    </Button>
                  )}
              </div>
            }
            <div className="flex items-center gap-2">
              {displayForBrand('') && (
                <Link to="/wallet">
                  <IconButton
                    src="/assets/svgs/fi_wallet.svg"
                    tooltip={String(t('common.wallet'))}
                  />
                </Link>
              )}

              {renderProfileDropdown(t)}
              <LanguageSelector />
            </div>
          </div>
        </>
      )}

      {isMobile && (
        <div className="w-full px-[12px] sm:px-[20px] rounded-b-md">
          <div
            className={`w-full ${expand ? 'h-[680px]' : 'h-[60px]'} transition-all ease-in-out duration-500 relative overflow-hidden bg-white`}
          >
            <div className="flex justify-between items-center h-[68px] w-full">
              <Link to="/">
                <IconButton
                  src="/assets/Logo.svg"
                  hoverSrc="/assets/svgs/fi_avatar_hover.svg"
                  tooltip={String(t('common.profile'))}
                />
              </Link>
              {!hasSubscription && displayForBrand('') && (
                <Button onClick={handleBoostClick} color="secondary">
                  {t('common.upgradeNow')}
                </Button>
              )}
              <button onClick={toggleExpand} className="size-6 cursor-pointer">
                {expand ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 rounded-md"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                )}
              </button>
            </div>
            {expand && (
              <div className="mt-4">
                <div className="flex items-center gap-3">
                  {displayForBrand('') && (
                    <Link to="/wallet">
                      <IconButton
                        src="/assets/svgs/fi_wallet.svg"
                        tooltip={String(t('common.wallet'))}
                      />
                    </Link>
                  )}

                  {renderProfileDropdown(t)}
                  <LanguageSelector side="left" />
                </div>
                <ul className="mt-4">
                  {sidebarData.map((val, index) => (
                    <li key={index}>
                      {val.route &&
                      user?.role &&
                      val.type.includes(user.role) &&
                      displayForBrand(val) ? (
                        <NavLink
                          to={val.route}
                          onClick={toggleExpand}
                          className={({ isActive }) =>
                            `flex items-center cursor-pointer py-[12px] px-2 rounded-full font-content ${
                              isActive
                                ? 'bg-gray-100 text-black'
                                : 'bg-transparent text-[#4D494F]'
                            }`
                          }
                        >
                          <img
                            src={val?.icon}
                            alt={val?.label}
                            className="ml-[10px] mr-[12px]"
                          />
                          <div className="flex items-center justify-between w-full">
                            <div>
                              <span className="whitespace-nowrap">
                                {t(
                                  `common.${val.label.toLowerCase().replace(/\s/g, '')}`,
                                )}
                              </span>
                            </div>
                            <div>
                              {val.access === 'limited' && !hasSubscription && (
                                <PremiumIcon />
                              )}
                            </div>
                          </div>
                        </NavLink>
                      ) : (
                        user &&
                        !val.route &&
                        val.type.includes(user.role) &&
                        displayForBrand(val) && (
                          <div className="flex items-center cursor-pointer py-[12px] rounded-full font-content bg-transparent text-[#afafaf]">
                            <img
                              src={val?.icon}
                              alt={val?.label}
                              className="ml-[10px] mr-[12px]"
                            />
                            <div className="lg:flex">
                              <span className="mr-2">
                                {t(
                                  `common.${val.label.toLowerCase().replace(/\s/g, '')}`,
                                )}
                              </span>
                              <span className="text-primary font-bold">
                                {t('common.soon')}
                              </span>
                            </div>
                          </div>
                        )
                      )}
                    </li>
                  ))}
                </ul>
                <>
                  {displayCompletion() && (
                    <div className="bg-[#F0F5FF] p-[24px] rounded-lg mt-4">
                      <h1 className="font-header text-[24px] font-[700]">
                        {t('profile.greeting', { name: user?.firstName })}
                      </h1>
                      <span className="text-[14px] mt-[10px] leading-none font-content">
                        {t('profile.incompleteProfile')}
                      </span>
                      <div className="my-[24px]">
                        <ProgressBar completed={completion} />
                      </div>
                      <Button
                        className="w-full"
                        variant="outlined"
                        onClick={handleCompleteProfileClick}
                      >
                        {t('profile.completeProfile')}
                      </Button>
                    </div>
                  )}
                </>
                <Copyright />
              </div>
            )}
          </div>
        </div>
      )}
      <UpgradeRequiredModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        onUpgrade={handleUpgradeClick}
      />
    </nav>
  );
};

export default Navbar;
