import React from 'react';
import classNames from 'classnames';

// Define the possible variant and color types
export type ButtonVariant = 'contained' | 'outlined' | 'text' | string;
export type ButtonColor = 'primary' | 'secondary' | 'default' | string;
export type IconPosition = 'start' | 'end';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The variant of the button.
   * - 'contained': solid background
   * - 'outlined': outlined border
   * - 'text': text only
   * - string: custom handling in your own tailwind classes
   */
  variant?: ButtonVariant;

  /**
   * The color style of the button.
   * - 'primary' => default to a custom dark-blue style (#113E53)
   * - 'secondary' => default to a bright green style (#59FF93)
   * - 'default' => default to a white/gray style
   * - string => custom color classes in `className`
   */
  color?: ButtonColor;

  /** An optional icon to display in the button. */
  icon?: React.ReactNode;

  /** Position of the icon relative to the text: 'start' | 'end'. */
  iconPosition?: IconPosition;

  /** Spacing between the icon and the text (Tailwind spacing scale). */
  iconSpacing?: number;

  /** Additional className for overriding or extending styles. */
  className?: string;
}

/**
 * Helper function to return Tailwind classes based on variant & color
 */
const getVariantClasses = (variant: ButtonVariant, color: ButtonColor) => {
  switch (variant) {
    case 'contained':
      switch (color) {
        case 'primary':
          return [
            'bg-[#113E53]', // Base color
            'text-white',
            'hover:bg-[#0C2F40]', // Darker hover color
            'active:bg-[#0A2734]', // Even darker on click
          ].join(' ');
        case 'secondary':
          return [
            'bg-[#59FF93]', // Base green color
            'text-[#113E53]', // Dark text for contrast
            'hover:bg-[#4AE97D]', // Darker green for hover
            'active:bg-[#A9F9B3]', // Lighter green for active (click)
          ].join(' ');
        case 'default':
          return [
            'bg-[#F1F5F9]', // New light grayish color
            'text-[#113E53]', // Dark text for contrast
            'hover:bg-[#E0E4EB]', // Slightly darker on hover
            'active:bg-[#D0D5DB]', // Even darker on click
          ].join(' ');
        default:
          return ['bg-gray-100', 'text-gray-800', 'hover:bg-gray-200'].join();
      }

    case 'outlined':
      switch (color) {
        case 'primary':
          return [
            'border',
            'border-[#113E53]',
            'text-[#113E53]',
            'hover:bg-[#0C2F40]', // Darker hover color
            'hover:text-white',
            'active:bg-[#0A2734]', // Even darker on click
          ].join(' ');
        case 'secondary':
          return [
            'border',
            'border-[#59FF93]',
            'text-[#59FF93]',
            'hover:bg-[#E0F9E3]',
            'active:bg-[#E0F9E3]',
          ].join(' ');
        case 'default':
          return [
            'border',
            'border-[#F1F5F9]',
            'text-[#113E53]',
            'hover:bg-[#E0E4EB]',
            'active:bg-[#D0D5DB]',
          ].join(' ');
        default:
          return [
            'border',
            'border-gray-400',
            'text-gray-800',
            'hover:bg-gray-50',
            'active:bg-gray-300',
          ].join(' ');
      }

    case 'text':
      switch (color) {
        case 'primary':
          return [
            'text-[#113E53]',
            'hover:bg-[#E7EDF1]',
            'active:bg-[#D9E4EB]',
          ].join(' ');
        case 'secondary':
          return [
            'text-[#59FF93]', // Light green text color
            'hover:bg-[#E0F9E3]', // Light green hover background
            'active:bg-[#A9F9B3]', // Lighter green active background
          ].join(' ');
        case 'default':
          return [
            'text-[#113E53]', // Dark text for contrast
            'hover:bg-[#E0E4EB]', // Slightly darker on hover
            'active:bg-[#D0D5DB]', // Even darker on click
          ].join(' ');
        default:
          return [
            'text-gray-800',
            'hover:bg-gray-50',
            'active:bg-gray-300',
          ].join(' ');
      }

    default:
      return '';
  }
};

export const Button: React.FC<ButtonProps> = ({
  variant = 'contained',
  color = 'primary',
  icon,
  iconPosition = 'start',
  iconSpacing = 2,
  className,
  children,
  disabled = false, // Destructure the disabled prop
  ...rest
}) => {
  // Base classes reflecting the Figma specs (14px, 700, line-height ~20px, etc.),
  // and pill shape. All can be overridden by passing a custom className.
  const baseClasses = [
    'inline-flex',
    'items-center',
    'justify-center',
    'rounded-full', // Pill shape
    'font-manrope', // Custom font (must be configured in Tailwind or loaded)
    'font-bold', // Weight 700
    'text-sm', // ~14px
    'font-header',
    'leading-5', // ~20px line-height
    'tracking-[0.015em]', // ~1.5% letter spacing
    'px-8',
    'py-3',
    'focus:outline-none', // Remove default focus outline
    'active:transform active:scale-95', // Press effect
    'transition-transform transition-shadow',
    'focus:outline-none',
    'focus-visible:outline-none',
    'focus-visible:ring-0',
  ].join(' ');

  const variantClasses = getVariantClasses(variant, color);

  // Add disabled-specific styles if the button is disabled
  const disabledClasses = disabled
    ? 'opacity-50 cursor-not-allowed' // Reduced opacity and no cursor pointer
    : '';

  // Build a margin class for spacing between icon and text
  const iconMarginClass =
    iconPosition === 'start' ? `mr-${iconSpacing}` : `ml-${iconSpacing}`;

  return (
    <button
      className={classNames(
        baseClasses,
        variantClasses,
        disabledClasses,
        className,
      )}
      disabled={disabled} // Pass the disabled prop to the button element
      {...rest}
    >
      {/* If icon is passed and iconPosition is 'start' */}
      {icon && iconPosition === 'start' && (
        <span className={iconSpacing ? iconMarginClass : ''}>{icon}</span>
      )}

      {/* The button text (children) */}
      {children}

      {/* If icon is passed and iconPosition is 'end' */}
      {icon && iconPosition === 'end' && (
        <span className={iconSpacing ? iconMarginClass : ''}>{icon}</span>
      )}
    </button>
  );
};
