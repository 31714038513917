/**
 * Microsoft Clarity Analytics Service
 *
 * Ce service vous permet d'interagir avec l'API Clarity dans votre application React.
 * Il offre des méthodes pour suivre des événements personnalisés et contrôler le comportement de Clarity.
 */

// Définir le type global pour Clarity
declare global {
  interface Window {
    clarity?: {
      (command: string, ...args: any[]): void;
      q?: any[];
    };
  }
}

/**
 * Vérifie si Clarity est chargé et disponible dans le navigateur
 */
const isClarityAvailable = (): boolean => {
  return typeof window !== 'undefined' && typeof window.clarity === 'function';
};

/**
 * Identifie un utilisateur dans Clarity
 * @param userId - Identifiant unique de l'utilisateur
 */
export const identifyUser = (userId: string): void => {
  if (isClarityAvailable() && window.clarity) {
    window.clarity('identify', userId);
  }
};

/**
 * Enregistre un événement personnalisé dans Clarity
 * @param eventName - Nom de l'événement
 * @param metadata - Données associées à l'événement (optionnel)
 */
export const trackEvent = (
  eventName: string,
  metadata?: Record<string, any>,
): void => {
  if (isClarityAvailable() && window.clarity) {
    if (metadata) {
      window.clarity('event', eventName, metadata);
    } else {
      window.clarity('event', eventName);
    }
  }
};

/**
 * Définit une propriété utilisateur dans Clarity
 * @param key - Clé de la propriété
 * @param value - Valeur de la propriété
 */
export const setUserProperty = (
  key: string,
  value: string | number | boolean,
): void => {
  if (isClarityAvailable() && window.clarity) {
    window.clarity('set', key, value);
  }
};

/**
 * Désactive le suivi de session pour le consentement RGPD/CCPA
 */
export const disableTracking = (): void => {
  if (isClarityAvailable() && window.clarity) {
    window.clarity('consent', false);
  }
};

/**
 * Active le suivi de session après consentement RGPD/CCPA
 */
export const enableTracking = (): void => {
  if (isClarityAvailable() && window.clarity) {
    window.clarity('consent', true);
  }
};

/**
 * Objet service exporté par défaut
 */
const ClarityService = {
  identifyUser,
  trackEvent,
  setUserProperty,
  disableTracking,
  enableTracking,
};

export default ClarityService;
