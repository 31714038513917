import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Import resources directly
import enTranslation from './locales/en/translation.json';
import frTranslation from './locales/fr/translation.json';

const STORAGE_KEY = 'i18nextLng';

// Translation resources
const resources = {
  en: {
    translation: enTranslation,
  },
  fr: {
    translation: frTranslation,
  },
};

const languageOptions = {
  order: ['localStorage', 'navigator'],
  lookupLocalStorage: STORAGE_KEY,
  caches: ['localStorage'],
  detectRobotLanguage: false,
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    detection: languageOptions,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['translation'],
    defaultNS: 'translation',
    // Use imported resources in all environments
    resources: resources,
  });

// Synchroniser avec userLanguagePreference si disponible
const userPref = localStorage.getItem('userLanguagePreference');
if (userPref && (userPref === 'en' || userPref === 'fr')) {
  i18n.changeLanguage(userPref);
  localStorage.setItem(STORAGE_KEY, userPref);
}

export default i18n;
