import { ROUTES } from '../routes/constants';

export const sidebarData = [
  {
    label: 'Dashboard',
    icon: '/assets/dashboardIcon.svg',
    route: ROUTES.HOME,
    type: ['user', 'brandUser', 'admin'],
    access: 'free',
  },
  {
    label: 'My Links',
    icon: '/assets/linkIcon.svg',
    route: ROUTES.MY_LINKS,
    type: ['user', 'brandUser'],
    access: 'free',
  },
  {
    label: 'Bulk Upload',
    icon: '/assets/uploadIcon.svg',
    route: ROUTES.BULK_UPLOAD,
    type: ['user', 'brandUser'],
    access: 'limited',
  },
  {
    label: 'Analytics',
    icon: '/assets/analyticsIcon.svg',
    route: ROUTES.ANALYTICS,
    type: ['user', 'brandUser'],
    access: 'limited',
  },
  {
    label: 'userManagement',
    icon: '/assets/analyticsIcon.svg',
    route: ROUTES.ADMIN_USERS,
    type: ['admin'],
    access: 'free',
  },
  {
    label: 'User Management',
    icon: '/assets/users.svg',
    route: ROUTES.USER_MANAGEMENT,
    type: ['brandUser'],
    access: 'free',
  },
  {
    label: 'Campaign',
    icon: '/assets/compaignIcon.svg',
    type: ['influencer', 'brand'],
    route: undefined,
    access: 'limited',
  },
];

export const COUNTRIES = [
  { name: 'EN', code: 'en' },
  { name: 'FR', code: 'fr' },
];
