import { toast } from 'react-toastify';
import api from '../config/axiosConfig';
import i18next from 'i18next';

export class AuthService {
  static getAccessToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  static setTokens(accessToken: string, refreshToken: string): void {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }

  static clearTokens(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  static isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  static async login(email: string, password: string): Promise<boolean> {
    try {
      const response = await api.post('/auth/login', { email, password });

      if (
        response.status === 200 &&
        response.data.accessToken &&
        response.data.refreshToken
      ) {
        this.setTokens(response.data.accessToken, response.data.refreshToken);

        toast.success(i18next.t('auth.loginSuccess'));
        return true;
      } else {
        toast.error(i18next.t('auth.loginFailed'));
        return false;
      }
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            toast.error(i18next.t('auth.invalidCredentials'));
            break;
          case 403:
            toast.error(i18next.t('auth.accountNotVerified'));
            break;
          case 404:
            toast.error(i18next.t('auth.userNotFound'));
            break;
          default:
            toast.error(i18next.t('auth.loginFailed'));
        }
      } else {
        toast.error(i18next.t('auth.networkError'));
      }
      return false;
    }
  }

  static async logout(): Promise<void> {
    try {
      // Optionnel: appel API pour invalider le token sur le serveur
      // await axiosInstance.post('/auth/logout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      this.clearTokens();
      window.location.href = '/signin';
    }
  }

  static async refreshToken(): Promise<string | null> {
    try {
      const refreshToken = this.getRefreshToken();

      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await api.post('/auth/refreshToken', { refreshToken });

      if (
        response.status === 200 &&
        response.data.accessToken &&
        response.data.refreshToken
      ) {
        this.setTokens(response.data.accessToken, response.data.refreshToken);
        return response.data.accessToken;
      }

      return null;
    } catch (error) {
      console.error('Error refreshing token:', error);
      this.clearTokens();
      return null;
    }
  }

  static async register(userData: any): Promise<boolean> {
    try {
      const response = await api.post('/auth/register', userData);

      if (response.status === 201 || response.status === 200) {
        toast.success(i18next.t('auth.registrationSuccess'));
        return true;
      } else {
        toast.error(i18next.t('auth.registrationFailed'));
        return false;
      }
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 409:
            toast.error(i18next.t('auth.emailAlreadyExists'));
            break;
          default:
            toast.error(i18next.t('auth.registrationFailed'));
        }
      } else {
        toast.error(i18next.t('auth.networkError'));
      }
      return false;
    }
  }

  static async forgotPassword(email: string): Promise<boolean> {
    try {
      const response = await api.post('/auth/forgot-password', { email });

      if (response.status === 200) {
        toast.success(i18next.t('auth.passwordResetEmailSent'));
        return true;
      } else {
        toast.error(i18next.t('auth.passwordResetFailed'));
        return false;
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        toast.error(i18next.t('auth.emailNotFound'));
      } else {
        toast.error(i18next.t('auth.passwordResetFailed'));
      }
      return false;
    }
  }

  static async resetPassword(
    token: string,
    newPassword: string,
  ): Promise<boolean> {
    try {
      const response = await api.post('/auth/reset-password', {
        token,
        newPassword,
      });

      if (response.status === 200) {
        toast.success(i18next.t('auth.passwordResetSuccess'));
        return true;
      } else {
        toast.error(i18next.t('auth.passwordResetFailed'));
        return false;
      }
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            toast.error(i18next.t('auth.invalidToken'));
            break;
          case 404:
            toast.error(i18next.t('auth.userNotFound'));
            break;
          default:
            toast.error(i18next.t('auth.passwordResetFailed'));
        }
      } else {
        toast.error(i18next.t('auth.networkError'));
      }
      return false;
    }
  }
}
