import React from 'react';
import classNames from 'classnames';

interface LoadingProps {
  size?: 'sm' | 'md' | 'lg';
  color?: string;
  className?: string;
  fullScreen?: boolean;
}

const Loading: React.FC<LoadingProps> = ({
  size = 'md',
  color = '#4F46E5',
  className,
  fullScreen = false,
}) => {
  const sizeMap = {
    sm: 'h-4 w-4 border-2',
    md: 'h-8 w-8 border-4',
    lg: 'h-12 w-12 border-4',
  };

  const spinnerSize = sizeMap[size];

  const containerClasses = classNames(
    'flex items-center justify-center',
    {
      'fixed inset-0 z-50 bg-white bg-opacity-75': fullScreen,
      'w-full h-full': !fullScreen,
    },
    className,
  );

  return (
    <div className={containerClasses} data-testid="loading">
      <div
        className={classNames('animate-spin rounded-full', spinnerSize)}
        style={{
          borderColor: `${color}40`,
          borderTopColor: color,
        }}
      />
    </div>
  );
};

export default Loading;
