export async function fetchMetaPixelScript() {
  try {
    const response = await fetch(
      'https://connect.facebook.net/en_US/fbevents.js',
    );
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    return await response.text();
  } catch (error) {
    console.error('Failed to fetch Meta Pixel script:', error);
    return null;
  }
}
