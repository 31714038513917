import React, { useEffect } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  direction?: 'center' | 'bottom';
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  className,
  direction = 'center',
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Re-enable scrolling
    }

    return () => {
      document.body.style.overflow = ''; // Cleanup when modal unmounts
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 bg-gray-300 bg-opacity-75 flex justify-center z-50 ${
        direction === 'bottom' ? 'items-end px-0' : 'items-center px-4'
      }  ${className ? className : ''}`}
      onClick={onClose}
    >
      <div
        className={`bg-white shadow-lg z-50 p-6 ${
          direction === 'bottom'
            ? 'rounded-t-2xl w-full motion-safe:animate-[slideUp_0.3s_ease-out]'
            : 'rounded-2xl mx-4'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
