import { forwardRef } from 'react';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  errorMessage?: string;
  iconWrapperClass?: string;
  containerWrapperClass?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      labelProps,
      iconWrapperClass,
      className,
      errorMessage,
      icon,
      iconPosition = 'start',
      containerWrapperClass,
      ...props
    }: InputProps,
    ref,
  ) => {
    return (
      <>
        {labelProps && (
          <label
            {...labelProps}
            className={`mb-2 block overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-medium leading-[25px] ${
              labelProps?.className || ''
            }`}
          />
        )}
        {/* <div className="relative flex-1"> */}
        <div className={`relative ${containerWrapperClass || ''}`}>
          {icon && iconPosition === 'start' && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
              <div
                className={`w-4 h-4 text-gray-400 ${iconWrapperClass || ''}`}
              >
                {icon}
              </div>
            </div>
          )}
          <input
            ref={ref}
            className={`w-full overflow-ellipsis whitespace-nowrap rounded-[32px] bg-white py-3 px-4 disabled:border-[#B3B3B2]  disabled:bg-[#F4F4F4] disabled:text-[#B3B3B2] outline-none focus:border focus:border-[#113E53] text-[#113E53] ${
              icon
                ? iconPosition === 'start'
                  ? 'pl-[40px]'
                  : 'pr-[45px]'
                : 'py-3 px-4'
            } text-base leading-[22px] placeholder-[#B3B3B2] ${
              errorMessage ? 'border border-red-500' : 'border border-[#B3B3B2]'
            } ${className || ''}`}
            {...props}
          />
          {icon && iconPosition === 'end' && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
              <div className="w-4 h-4 text-gray-400">{icon}</div>
            </div>
          )}
          {errorMessage && (
            <p className="mt-[2px] text-red-500 text-xs">{errorMessage}</p>
          )}
        </div>
      </>
    );
  },
);

Input.displayName = 'Input';

export default Input;
