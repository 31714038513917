import { useState, useEffect } from 'react';

interface DeviceState {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

/**
 * Hook pour détecter le type d'appareil en fonction de la taille de l'écran
 * @returns {DeviceState} L'état de détection de l'appareil
 */
function useDeviceDetect(): DeviceState {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowWidth < 768;
  const isTablet = windowWidth >= 768 && windowWidth < 1024;
  const isDesktop = windowWidth >= 1024;

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
}

export default useDeviceDetect;
