import { ROUTES } from './constants';

export const PUBLIC_ROUTES = [
  ROUTES.SIGNIN,
  ROUTES.SIGNUP,
  ROUTES.SIGNUP_INFLUENCER,
  ROUTES.SIGNUP_BRAND,
  ROUTES.FORGET_PASSWORD,
  ROUTES.TOKEN_REDIRECT,
  ROUTES.SOCIAL_AUTH,
  ROUTES.REDIRECT,
  ROUTES.ACTIVATE_ACCOUNT,
];

export const isPublicRoute = (path: string): boolean => {
  return PUBLIC_ROUTES.some((route) => {
    if (route.includes(':')) {
      const routeRegex = new RegExp(
        '^' + route.replace(/:[^\s/]+/g, '([^/]+)') + '$',
      );
      return routeRegex.test(path);
    }
    return route === path;
  });
};
