import { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import useLocalStorage from './useLocalStorage';
import useAPI from './useAPI';

interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

interface LoginResponse {
  user: any;
  tokens: AuthTokens;
}

/**
 * Hook pour gérer les fonctionnalités d'authentification
 */
const useAuth = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const user = userContext?.user || null;
  const setUser =
    userContext?.setUser ||
    ((_: any) => console.warn('UserContext not available'));
  const [tokens, setTokens, resetTokens] = useLocalStorage<AuthTokens | null>(
    'auth_tokens',
    null,
  );
  const api = useAPI();

  /**
   * Vérifier si l'utilisateur est authentifié
   */
  const isAuthenticated = useCallback(() => {
    return !!user && !!tokens?.accessToken;
  }, [user, tokens]);

  /**
   * Connecter un utilisateur
   */
  const login = useCallback(
    async (credentials: LoginCredentials) => {
      const loginAPI = api.post<LoginResponse>('/api/auth/login', credentials, {
        showSuccessToast: true,
        successMessage: 'Connexion réussie',
      });

      const response = await loginAPI.submit();

      if (response) {
        setUser(response.user);
        setTokens(response.tokens);
        return true;
      }

      return false;
    },
    [api, setUser, setTokens],
  );

  /**
   * Déconnecter l'utilisateur
   */
  const logout = useCallback(async () => {
    // Appeler l'API de déconnexion si nécessaire
    if (tokens) {
      await api
        .post(
          '/api/auth/logout',
          {},
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          },
        )
        .submit();
    }

    // Réinitialiser l'état local
    setUser(null);
    resetTokens();

    // Rediriger vers la page de connexion
    navigate('/login');
  }, [api, tokens, setUser, resetTokens, navigate]);

  /**
   * Rafraîchir le token d'accès
   */
  const refreshAccessToken = useCallback(async (): Promise<boolean> => {
    if (!tokens?.refreshToken) return false;

    const refreshAPI = api.post<{ accessToken: string }>('/api/auth/refresh', {
      refreshToken: tokens.refreshToken,
    });

    const response = await refreshAPI.submit();

    if (response) {
      setTokens({
        ...tokens,
        accessToken: response.accessToken,
      });
      return true;
    }

    // Si le rafraîchissement échoue, déconnecter l'utilisateur
    logout();
    return false;
  }, [api, tokens, setTokens, logout]);

  return {
    user,
    isAuthenticated,
    login,
    logout,
    refreshAccessToken,
    tokens,
  };
};

export default useAuth;
