import { useState, useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import useI18n from './useI18n';

type APIMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface APIOptions extends Omit<AxiosRequestConfig, 'url'> {
  fetchImmediately?: boolean;
  method?: APIMethod;
  body?: any;
  headers?: Record<string, string>;
  credentials?: RequestCredentials;
  showSuccessToast?: boolean;
  successMessage?: string;
  showErrorToast?: boolean;
  errorMessage?: string;
}

interface APIState<T> {
  data: T | null;
  isLoading: boolean;
  error: Error | null;
}

/**
 * Hook pour gérer les appels API
 */
const useAPI = () => {
  const { t } = useI18n();

  // Fonction pour gérer un appel API générique
  const callAPI = <T = any>(initialState: T | null = null) => {
    const [data, setData] = useState<T | null>(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    const execute = useCallback(
      async (url: string, options: APIOptions = {}) => {
        try {
          setIsLoading(true);
          setError(null);

          const response = await axios({
            url,
            ...options,
          });

          setData(response.data);
          return response.data;
        } catch (err) {
          const error = err instanceof Error ? err : new Error(String(err));
          setError(error);
          return null;
        } finally {
          setIsLoading(false);
        }
      },
      [],
    );

    const reset = useCallback(() => {
      setData(initialState);
      setError(null);
      setIsLoading(false);
    }, [initialState]);

    return {
      execute,
      reset,
      data,
      isLoading,
      error,
    };
  };

  // Fonction pour les requêtes GET
  const get = <T = any>(url: string, options?: Omit<APIOptions, 'method'>) => {
    const api = callAPI<T>();

    return {
      fetch: (customOptions = {}) =>
        api.execute(url, {
          method: 'GET',
          ...options,
          ...customOptions,
        }),
      data: api.data,
      isLoading: api.isLoading,
      error: api.error,
      reset: api.reset,
    };
  };

  // Fonction pour les requêtes POST
  const post = <T = any>(
    url: string,
    data: any,
    options?: Omit<APIOptions, 'method' | 'data'>,
  ) => {
    const api = callAPI<T>();

    return {
      fetch: (customOptions = {}) =>
        api.execute(url, {
          method: 'POST',
          data,
          ...options,
          ...customOptions,
        }),
      data: api.data,
      isLoading: api.isLoading,
      error: api.error,
      reset: api.reset,
    };
  };

  const put = <T = any>(
    url: string,
    data: any,
    options?: Omit<APIOptions, 'method'>,
  ) => {
    const api = callAPI<T>();
    return {
      ...api,
      update: () => api.execute(url, { ...options, method: 'PUT', body: data }),
    };
  };

  const del = <T = any>(url: string, options?: Omit<APIOptions, 'method'>) => {
    const api = callAPI<T>();
    return {
      ...api,
      remove: () => api.execute(url, { ...options, method: 'DELETE' }),
    };
  };

  return {
    callAPI,
    get,
    post,
    put,
    delete: del,
  };
};

export default useAPI;
