import { useState, useEffect, useCallback } from 'react';

/**
 * Hook pour manipuler facilement les données dans le localStorage avec typage
 * @param key Clé à utiliser dans le localStorage
 * @param initialValue Valeur initiale si aucune valeur n'existe
 * @returns Tuple [valeur actuelle, fonction setter, fonction reset]
 */
function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((val: T) => T)) => void, () => void] {
  // Fonction pour obtenir la valeur initiale
  const readValue = useCallback((): T => {
    // Vérifier si window est disponible (pour éviter les erreurs SSR)
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      // Récupérer la valeur du localStorage
      const item = window.localStorage.getItem(key);

      // Retourner la valeur parsée ou initialValue si null
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.warn(
        `Erreur lors de la lecture de localStorage pour "${key}":`,
        error,
      );
      return initialValue;
    }
  }, [initialValue, key]);

  // État pour stocker la valeur actuelle
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Fonction pour mettre à jour la valeur dans le state et localStorage
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      // Vérifier si window est disponible
      if (typeof window === 'undefined') {
        console.warn(
          `Impossible de définir localStorage car l'environnement n'est pas un navigateur.`,
        );
        return;
      }

      try {
        // Déterminer la nouvelle valeur
        const newValue = value instanceof Function ? value(storedValue) : value;

        // Mettre à jour localStorage
        window.localStorage.setItem(key, JSON.stringify(newValue));

        // Mettre à jour le state
        setStoredValue(newValue);
      } catch (error) {
        console.warn(
          `Erreur lors de l'écriture dans localStorage pour "${key}":`,
          error,
        );
      }
    },
    [key, storedValue],
  );

  // Fonction pour réinitialiser la valeur à sa valeur initiale
  const reset = useCallback(() => {
    setValue(initialValue);
  }, [initialValue, setValue]);

  // Écouter les changements de localStorage
  useEffect(() => {
    // Callback pour l'événement 'storage'
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key && e.newValue) {
        try {
          // Mettre à jour notre état avec la nouvelle valeur
          const newValue = JSON.parse(e.newValue) as T;
          setStoredValue(newValue);
        } catch (error) {
          console.warn(
            `Erreur lors du parsing de la nouvelle valeur de localStorage pour "${key}":`,
            error,
          );
        }
      }
    };

    // Écouter les changements
    window.addEventListener('storage', handleStorageChange);

    // Nettoyage
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return [storedValue, setValue, reset];
}

export default useLocalStorage;
