import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

/**
 * Hook pour gérer les vérifications liées aux rôles des utilisateurs
 */
const useRole = () => {
  const { user } = useContext(UserContext) || {};

  /**
   * Vérifie si l'utilisateur possède un rôle spécifique
   * @param role Rôle à vérifier
   */
  const hasRole = (role: string | string[]): boolean => {
    if (!user || !user.role) return false;

    if (Array.isArray(role)) {
      return role.includes(user.role);
    }

    return user.role === role;
  };

  /**
   * Vérifie si l'utilisateur est un administrateur
   */
  const isAdmin = (): boolean => {
    return hasRole('admin');
  };

  /**
   * Vérifie si l'utilisateur est un utilisateur normal
   */
  const isRegularUser = (): boolean => {
    return hasRole('user');
  };

  /**
   * Vérifie si l'utilisateur est associé à une marque
   */
  const isBrandUser = (): boolean => {
    return hasRole('brandUser');
  };

  return {
    hasRole,
    isAdmin,
    isRegularUser,
    isBrandUser,
    userRole: user?.role,
  };
};

export default useRole;
