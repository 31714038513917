import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';

/**
 * Custom hook for all i18n related utilities
 */
const useI18n = () => {
  const { t, i18n } = useTranslation();
  const {
    currentLanguage,
    changeLanguage,
    languages,
    detectUserLanguage,
    saveLanguagePreference,
  } = useLanguage();

  // Date formatter based on current language
  const formatDate = (
    date: Date | string,
    options?: Intl.DateTimeFormatOptions,
  ) => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return new Intl.DateTimeFormat(currentLanguage, options).format(dateObj);
  };

  // Number formatter based on current language
  const formatNumber = (number: number, options?: Intl.NumberFormatOptions) => {
    return new Intl.NumberFormat(currentLanguage, options).format(number);
  };

  return {
    // Standard translation function
    t,
    // i18n instance
    i18n,
    // Current language
    currentLanguage,
    // Function to change language
    changeLanguage,
    // List of available languages
    languages,
    // Check if current language is the specified one
    isCurrentLanguage: (lang: string) => currentLanguage === lang,
    // Automatically detect user language
    detectUserLanguage,
    // Save language preference
    saveLanguagePreference,
    // Formatting utilities according to locale
    formatDate,
    formatNumber,
  };
};

export default useI18n;
