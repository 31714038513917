import React from 'react';
import Model from '../common/models/Model';
import { Button } from '../common/Button';
import useI18n from '../../hooks/useI18n';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpgrade: () => void;
}

const UpgradeRequiredModal: React.FC<UpgradeModalProps> = ({
  isOpen,
  onClose,
  onUpgrade,
}) => {
  const { t } = useI18n();

  return (
    <Model
      isOpen={isOpen}
      onClose={onClose}
      className="!bg-black !bg-opacity-40"
    >
      <div className="flex flex-col w-72">
        <h2 className="text-2xl mb-4 text-[#212121]">{t('upgrade.title')}</h2>
        <p className="text-[#212121] mb-4 text-sm">
          {t(
            'upgrade.limitReachedMessage',
            'You have reached your limit of 10 free smart links. To create more links, please upgrade your package.',
          )}
        </p>
        <div className="flex justify-center gap-2">
          <Button variant="outlined" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button onClick={onUpgrade}>{t('common.upgradeNow')}</Button>
        </div>
      </div>
    </Model>
  );
};

export default UpgradeRequiredModal;
