import React from 'react';

const Copyright: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="text-center mt-[24px] text-[12px] text-[#575757] font-[Inter]">
      © Linkfluencer, {currentYear}
    </div>
  );
};

export default Copyright;
