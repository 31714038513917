import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import i18n from '../i18n';
import { COUNTRIES } from '../config/sidebarData';
import { UserContext } from './UserContext';

interface LanguageContextType {
  currentLanguage: string;
  changeLanguage: (lang: string) => void;
  languages: { name: string; code: string }[];
  detectUserLanguage: () => void;
  saveLanguagePreference: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const userContext = useContext(UserContext);
  const user = userContext?.user;

  const [currentLanguage, setCurrentLanguage] = useState(() => {
    return i18n.language || localStorage.getItem('i18nextLng') || 'en';
  });

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    saveLanguagePreference(lang);
  };

  const saveLanguagePreference = (lang: string) => {
    localStorage.setItem('userLanguagePreference', lang);
    localStorage.setItem('i18nextLng', lang);
    if (user) {
      try {
        // This part should be implemented on the backend
        // updateUserLanguagePreference(user._id, lang);
      } catch (error) {
        console.error('Error saving language preference:', error);
      }
    }
  };

  const detectUserLanguage = () => {
    const savedPreference = localStorage.getItem('userLanguagePreference');
    if (
      savedPreference &&
      COUNTRIES.some((lang) => lang.code === savedPreference)
    ) {
      changeLanguage(savedPreference);
      return;
    }

    const browserLanguage = navigator.language.split('-')[0];
    const supportedLanguage = COUNTRIES.find(
      (lang) => lang.code === browserLanguage,
    );

    if (supportedLanguage) {
      changeLanguage(supportedLanguage.code);
    } else {
      changeLanguage('en');
    }
  };

  useEffect(() => {
    const handleLanguageChanged = (lng: string) => {
      setCurrentLanguage(lng);
    };

    i18n.on('languageChanged', handleLanguageChanged);

    setCurrentLanguage(i18n.language);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);

  useEffect(() => {
    const savedPreference = localStorage.getItem('userLanguagePreference');
    if (!savedPreference) {
      detectUserLanguage();
    }
  }, []);

  const value = {
    currentLanguage,
    changeLanguage,
    languages: COUNTRIES,
    detectUserLanguage,
    saveLanguagePreference,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export default LanguageContext;
