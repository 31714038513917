import { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import ClarityService from '../../services/clarityService';

/**
 * Composant invisible qui initialise et configure Microsoft Clarity
 * en fonction de l'état de l'utilisateur et des paramètres de l'application.
 *
 * Ce composant devrait être rendu une seule fois, au niveau le plus élevé de l'application.
 */
const ClarityAnalytics: React.FC = () => {
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    // Si l'utilisateur est authentifié, on l'identifie dans Clarity
    if (Boolean(isAuthenticated) && user?._id) {
      ClarityService.identifyUser(user._id);

      // Ajouter des propriétés utilisateur utiles pour la segmentation
      if (user.email) {
        ClarityService.setUserProperty('email', user.email);
      }

      if (user.role) {
        ClarityService.setUserProperty('role', user.role);
      }

      if (user.subscription?.plan) {
        ClarityService.setUserProperty('plan', user.subscription.plan);
      }
    }

    // Enregistrer la page d'atterrissage
    ClarityService.trackEvent('page_visit', {
      url: window.location.pathname,
      referrer: document.referrer,
    });
  }, [isAuthenticated, user]);

  // Suivre les changements de route
  useEffect(() => {
    const handleRouteChange = () => {
      ClarityService.trackEvent('page_change', {
        url: window.location.pathname,
      });
    };

    // Observer les changements d'historique pour SPA
    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, []);

  // Ce composant ne rend rien visuellement
  return null;
};

export default ClarityAnalytics;
