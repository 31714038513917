import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { AuthService } from '../services/authService';
import { ROUTES } from './constants';
import Loading from '../components/common/Loading';

function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

const ProtectedRoutes = () => {
  const { user, loading, fetchUser } = useUserContext();
  const location = useLocation();
  const isAuthenticated = AuthService.isAuthenticated();

  useEffect(() => {
    if (isAuthenticated && !user && !loading) {
      fetchUser();
    }
  }, [isAuthenticated, user, loading, fetchUser]);

  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.SIGNIN} state={{ from: location }} replace />;
  }

  if (!user) {
    return <Loading />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
