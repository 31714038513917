import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { BrandMemberRole } from '../types/enums';
import useSubscription from './useSubscription';

// Type qui définit les niveaux d'accès possibles
export type AccessLevel = 'free' | 'limited' | string;

/**
 * Custom hook to check user's brand access permissions
 */
const useBrandAccess = () => {
  const { user } = useContext(UserContext) || {};
  const { hasSubscription } = useSubscription();

  /**
   * Check if a feature is accessible to the brand user
   * @param featureAccess Access level required for the feature ('free' or 'limited')
   * @returns Boolean indicating if the user can access the feature
   */
  const canAccessFeature = (featureAccess: AccessLevel): boolean => {
    if (!user) return false;

    // Admin/manager check - they have full access
    if (
      user.brandMemberRole !== undefined &&
      user.brandMemberRole !== BrandMemberRole.User
    ) {
      return true;
    }

    // Access based on feature type
    if (featureAccess === 'free') {
      return true;
    }

    // Limited feature requires subscription
    if (featureAccess === 'limited') {
      return hasSubscription ?? false;
    }

    return false;
  };

  /**
   * Check if user is a brand admin (not regular User role)
   */
  const isBrandAdmin = (): boolean => {
    if (!user || user.brandMemberRole === undefined) return false;
    return user.brandMemberRole !== BrandMemberRole.User;
  };

  /**
   * Check if user has any brand member role
   */
  const isBrandMember = (): boolean => {
    if (!user) return false;
    return user.brandMemberRole !== undefined;
  };

  return {
    canAccessFeature,
    isBrandMember,
    isBrandAdmin,
  };
};

export default useBrandAccess;
