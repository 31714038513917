import { NavLink, useNavigate } from 'react-router-dom';
import ProgressBar from '../common/ProgressBar';
import { useMinimizeContext } from '../../context/LayoutContext';
import { sidebarData } from '../../config/sidebarData';
import { UserContext } from '../../context/UserContext';
import { useContext, useEffect, useState } from 'react';
import { ProfileCompletion } from '../../services/userService';
import { SidebarTooltip } from '../common/ToolTip';
import { PremiumIcon } from '../../svg';
import { Button } from '../common/Button';
import { useTranslation } from 'react-i18next';
import Loading from '../common/Loading';
import useSubscription from '../../hooks/useSubscription';
import useBrandAccess from '../../hooks/useBrandAccess';
import useRole from '../../hooks/useRole';
import Copyright from '../common/Copyright';

const Sidebar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { minimize } = useMinimizeContext();
  const { user } = useContext(UserContext) || {};
  const { hasSubscription } = useSubscription();
  const { canAccessFeature } = useBrandAccess();
  const { hasRole, isAdmin } = useRole();
  const [completion, setCompletion] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfileCompletion = async () => {
      if (!user || isAdmin()) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const data = await ProfileCompletion(user._id);
        setCompletion(data);
      } catch (error) {
        console.error('Error fetching profile completion:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProfileCompletion();
  }, [user]);

  const displayCompletion = () =>
    !isLoading && completion !== 100 && !minimize && user && !isAdmin();

  const handleCompleteProfileClick = () => {
    navigate('/profile');
  };

  const shouldShowMenuItem = (item: (typeof sidebarData)[0]): boolean => {
    if (!user) return false;

    const hasRequiredRole = item.type.some((role) => hasRole(role));

    const hasAccess = canAccessFeature(item.access);

    return hasRequiredRole && hasAccess;
  };

  return (
    <div
      style={{ maxHeight: 'calc(100vh - 97px)' }}
      className="relative flex flex-col justify-between w-full p-[24px] h-full"
    >
      <ul className="">
        {sidebarData.map((val, index) => (
          <li key={index}>
            {shouldShowMenuItem(val) &&
              (val.route ? (
                <NavLink
                  to={val.route}
                  className={({ isActive }) =>
                    `flex items-center cursor-pointer py-[12px] ${
                      !minimize && 'px-[5px] lg:px-[20px]'
                    } rounded-full font-content ${
                      isActive
                        ? 'bg-gray-100 text-black'
                        : 'bg-transparent text-[#4D494F] hover:bg-gray-100'
                    }`
                  }
                >
                  {minimize ? (
                    <SidebarTooltip text={val?.label} position="right">
                      <img
                        src={val?.icon}
                        alt="dashboard"
                        className={`ml-[10px] mr-[12px]`}
                      />
                    </SidebarTooltip>
                  ) : (
                    <img
                      src={val?.icon}
                      alt="dashboard"
                      className={`ml-[10px] mr-[12px]`}
                    />
                  )}

                  {!minimize && (
                    <div className="flex items-center justify-between w-full">
                      <div>
                        <span className="whitespace-nowrap">
                          {t(
                            `common.${val.label.toLowerCase().replace(/\s/g, '')}`,
                          )}
                        </span>
                      </div>
                      <div>
                        {val.access === 'limited' && !hasSubscription && (
                          <PremiumIcon />
                        )}
                      </div>
                    </div>
                  )}
                </NavLink>
              ) : (
                <div
                  className={`flex items-center cursor-pointer py-[12px] ${
                    !minimize && 'px-[5px] lg:px-[20]'
                  } rounded-full font-content bg-transparent text-[#afafaf]`}
                >
                  <img
                    src={val?.icon}
                    alt="dashboard"
                    className={`ml-[10px] mr-[12px]`}
                  />
                  <div className="flex flex-col lg:flex-row">
                    <span className={`${minimize ? 'hidden' : 'block'} mr-2`}>
                      {t(
                        `common.${val.label.toLowerCase().replace(/\s/g, '')}`,
                      )}
                    </span>
                    <span
                      className={`${
                        minimize ? 'hidden' : 'block'
                      } text-primary font-bold`}
                    >
                      {t('common.soon')}
                    </span>
                  </div>
                </div>
              ))}
          </li>
        ))}
      </ul>
      <div className="">
        {isLoading && !minimize && user && !isAdmin() ? (
          <div className="mt-[24px] h-[204px] bg-[#F0F5FF] p-[15px] rounded-lg flex items-center justify-center">
            <div className="scale-50">
              <Loading />
            </div>
          </div>
        ) : (
          displayCompletion() && (
            <div className="mt-[24px]">
              <div className="bg-[#F0F5FF] p-[15px] rounded-lg overflow-hidden">
                <h1 className="font-header text-[24px] font-[700] whitespace-nowrap">
                  {t('profile.greeting', { name: user?.firstName })}
                </h1>
                <span className="text-[14px] mt-[10px] leading-none font-content whitespace-nowrap">
                  {t('profile.incompleteProfile')}
                </span>
                <div className="my-[24px]">
                  <ProgressBar completed={completion} />
                </div>
                <Button
                  onClick={handleCompleteProfileClick}
                  variant="outlined"
                  className="w-full"
                >
                  {t('profile.completeProfile')}
                </Button>
              </div>
            </div>
          )
        )}
        {!minimize && <Copyright />}
      </div>
    </div>
  );
};

export default Sidebar;
