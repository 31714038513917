import React from 'react';

interface TooltipProps {
  text: string;
  children: React.ReactNode;
  className?: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, className }) => {
  return (
    <div className="relative group">
      {children}
      <div className="absolute z-10 invisible group-hover:visible bg-gray-800 text-white text-xs rounded py-1 px-2 -mt-1 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
        {text}
        <div className="absolute w-2 h-2 bg-gray-800 transform rotate-45 -mt-1 left-1/2 -translate-x-1/2"></div>
      </div>
    </div>
  );
};

export const SidebarTooltip: React.FC<TooltipProps> = ({
  text,
  children,
  className,
  position = 'right',
}) => {
  return (
    <div className="relative group">
      {children}
      <div
        className={`absolute z-10 invisible group-hover:visible bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap ${position === 'right' ? 'left-full ml-2 top-1/2 -translate-y-1/2' : ''}`}
      >
        {text}
        <div
          className={`absolute w-2 h-2 bg-gray-800 transform rotate-45 ${position === 'right' ? '-left-1 top-1/2 -translate-y-1/2' : ''}`}
        ></div>
      </div>
    </div>
  );
};

export default Tooltip;
