import React, { useEffect, useState } from 'react';
import useI18n from '../../hooks/useI18n';
import Dropdown from './Dropdown';

interface LanguageSelectorProps {
  side?: 'left' | 'right';
  className?: string;
  iconOnly?: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  side = 'right',
  className = '',
  iconOnly = false,
}) => {
  const { currentLanguage, changeLanguage, languages, i18n } = useI18n();

  // État local pour s'assurer que l'interface est à jour
  const [displayLanguage, setDisplayLanguage] = useState(currentLanguage);

  // S'assurer que l'affichage est mis à jour quand la langue change
  useEffect(() => {
    setDisplayLanguage(currentLanguage);
  }, [currentLanguage]);

  // S'assurer que nous avons la dernière langue à chaque rendu
  useEffect(() => {
    // La langue actuelle d'i18n est la source de vérité
    setDisplayLanguage(i18n.language);
  }, [i18n.language]);

  return (
    <div className={className}>
      <Dropdown
        side={side}
        label={
          <div className="flex items-center gap-2 select-none cursor-pointer">
            {iconOnly ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
                />
              </svg>
            ) : (
              <>
                <span>{displayLanguage.toUpperCase()}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </>
            )}
          </div>
        }
      >
        <ul className="w-max flex flex-col items-center bg-white border shadow-lg rounded-lg py-4">
          {languages.map((val, index) => (
            <li
              key={index}
              className={`hover:bg-gray-100 w-full px-4 py-2 text-center cursor-pointer ${
                displayLanguage === val.code ? 'font-bold' : ''
              }`}
              onClick={() => {
                changeLanguage(val.code);
                setDisplayLanguage(val.code);
              }}
            >
              {val.name}
            </li>
          ))}
        </ul>
      </Dropdown>
    </div>
  );
};

export default LanguageSelector;
