import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { MinimizeContextProvider } from './context/LayoutContext';
import RoutesConfig from './routes/RoutesConfig';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaPixel from './components/common/pixels/MetaPixel';
import { LanguageProvider } from './context/LanguageContext';
import './i18n';
import { Suspense } from 'react';
import ClarityAnalytics from './components/Analytics/ClarityAnalytics';

function App() {
  return (
    <div className="w-full max-w-full m-auto">
      <MetaPixel />
      <Suspense fallback={<div>Loading...</div>}>
        <LanguageProvider>
          <Router>
            <MinimizeContextProvider>
              <ClarityAnalytics />
              <RoutesConfig />
              <ToastContainer />
            </MinimizeContextProvider>
          </Router>
        </LanguageProvider>
      </Suspense>
    </div>
  );
}

export default App;
