import React, { useState, useMemo } from 'react';
import { Link } from './TableData';
import Pagination from './UserTablePagination';
import Input from '../../common/Input';
import IconButton from '../../common/IconButton';

interface Prop {
  handleClose?: () => void;
  links: Link[];
}

const LinkTableModal: React.FC<Prop> = ({ handleClose, links }) => {
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // State to manage sorting by clicks
  const itemsPerPage = 10;

  // Filter links across all columns
  const filteredLinks = useMemo(() => {
    return links?.filter((link) => {
      const linkValues = [
        link.originalUrl,
        link.shortUrl,
        link.targetSite,
        link.clickCount.toString(),
      ];

      return linkValues.some((value) =>
        value.toLowerCase().includes(filter.toLowerCase()),
      );
    });
  }, [filter, links]);

  // Sort links by number of clicks
  const sortedLinks = useMemo(() => {
    const sorted = [...(filteredLinks || [])];
    sorted.sort((a, b) => {
      return sortOrder === 'asc'
        ? a.clickCount - b.clickCount
        : b.clickCount - a.clickCount;
    });
    return sorted;
  }, [filteredLinks, sortOrder]);

  // Links pagination
  const paginatedLinks = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedLinks.slice(startIndex, endIndex);
  }, [currentPage, sortedLinks]);

  // Function to handle sorting when the user clicks on the "Clicks" header
  const handleSortByClicks = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-[90%] md:w-[80%] lg:w-[60%] p-6 rounded-lg shadow-lg">
        <div className="flex justify-between mb-6">
          <span className="text-xl font-semibold text-gray-800">Links</span>
          <IconButton
            src="/assets/svgs/fi_cross.svg"
            onClick={handleClose}
            className="size-50"
          />
        </div>

        {/* Display of total links and total clicks */}
        <div className="mb-4 text-right text-gray-700">
          <span className="font-bold">{filteredLinks?.length}</span> links
          found, with a total of{' '}
          <span className="font-bold">
            {filteredLinks?.reduce((sum, link) => sum + link.clickCount, 0)}
          </span>{' '}
          clicks.
        </div>

        {/* Search bar to filter links */}
        <Input
          type="text"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Search links"
          className="mb-4 rounded-2xl"
        />

        {/* Links table with ellipses and sorting by clicks */}
        <div className="overflow-x-auto rounded-2xl border border-gray-200 mt-1">
          <table className="min-w-full table-auto rounded-3xl">
            <thead>
              <tr className="bg-gray-100 flex items-center border h-[50px]">
                <th
                  className="font-semibold text-right px-4 py-2 w-[10%] cursor-pointer"
                  onClick={handleSortByClicks}
                >
                  Clicks {sortOrder === 'asc' ? '↑' : '↓'}{' '}
                  {/* Sort indicator */}
                </th>
                <th className="font-semibold text-center px-4 py-2 w-[20%]">
                  Target Site
                </th>
                <th className="font-semibold text-center px-4 py-2 w-[20%]">
                  Short URL
                </th>
                <th className="font-semibold text-left px-4 py-2 w-[50%]">
                  Original URL
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-300">
              {paginatedLinks.map((link) => (
                <tr
                  key={link.shortUrl}
                  className="bg-white transition-all duration-500 hover:bg-gray-50 border flex justify-start items-center relative"
                >
                  <td
                    className="whitespace-nowrap overflow-hidden px-4 py-4 text-sm text-right text-gray-900 w-[10%]"
                    title={link.clickCount.toString()}
                    style={{
                      maxWidth: '10%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {link.clickCount}
                  </td>
                  <td
                    className="whitespace-nowrap overflow-hidden px-4 py-4 text-sm text-center text-gray-900 w-[20%] max-w-[100px] overflow-ellipsis"
                    title={link.targetSite}
                    style={{
                      maxWidth: '20%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {link.targetSite}
                  </td>
                  <td
                    className="whitespace-nowrap overflow-hidden px-4 py-4 text-sm text-center text-gray-900 w-[20%] max-w-[100px] overflow-ellipsis"
                    title={link.shortUrl}
                    style={{
                      maxWidth: '20%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {link.shortUrl}
                  </td>
                  <td
                    className="whitespace-nowrap overflow-hidden px-4 py-4 text-sm text-gray-900 w-[50%] overflow-ellipsis"
                    title={link.originalUrl}
                    style={{
                      maxWidth: '50%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {link.originalUrl}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="mt-4 flex justify-center">
          <Pagination
            totalItems={filteredLinks?.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            limit={{
              start: (currentPage - 1) * itemsPerPage + 1,
              end: Math.min(currentPage * itemsPerPage, filteredLinks?.length),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LinkTableModal;
