import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

/**
 * Hook pour gérer les abonnements utilisateur
 * @returns Informations sur l'abonnement de l'utilisateur
 */
const useSubscription = () => {
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error('useSubscription must be used within a UserProvider');
  }

  const { user, loading } = userContext;

  const hasSubscription = Boolean(
    user?.subscription?.hasSubscription || user?.isWhitelisted,
  );

  const isPremium = Boolean(
    hasSubscription &&
      (user?.subscription?.plan === 'monthly' ||
        user?.subscription?.plan === 'yearly'),
  );

  return {
    user,
    loading,
    hasSubscription,
    isPremium,
  };
};

export default useSubscription;
